import React from 'react';
import './styles.scss';

const Home = () => {
    return (
        <div className="home-page">
            <div className="big section">
                <img src="/Media/NH_6.png" alt="logo" className='bg'/>
                <div className="mask"></div>
                <div className="description-container py-5 px-2">
                    <h1>Property Management Simplified</h1>
                    <h2>Here at Nest Home, we strive to provide the best services aspiring AirBnB homestay owners can ever hope for.</h2>
                    <a href="/contact-us" className='btn btn-sm text-uppercase'>
                        Contact Us Now
                        <i className="fa fa-chevron-right ml-2"></i>
                    </a>
                </div>
            </div>
            <div className="big section mobile-height-unset">
                <div className="description-container dark">
                    <span>Our Clientele</span>

                    <div className="card-container h-100">
                        <div className="card-item">
                            <i className="fa fa-building"></i>
                            300+ Condos
                        </div>
                        <div className="card-item">
                            <i className="fa fa-user"></i>
                            250+ Owners
                        </div>
                        <div className="card-item">
                            <i className="fa fa-home"></i>
                            5+ Landed Houses
                        </div>
                        {/* <div className="circle"></div> */}
                    </div>
                </div>
            </div>
            <div className="big dark section">
                <div className="description-container dark">
                    <span className='mb-5'>We have over</span>
                    <div className="card-container mt-5 mb-5">
                        <span>160,000+ Bookings</span>
                    </div>
                    <span className='mt-5'>in just 5 years</span>
                </div>
            </div>
            <div className="free-size section mobile-height-unset">
                <div className="description-container dark">
                    <span className='mb-5'>We have satisfied guests</span>
                    <div className='image-container'>
                        <img src="/Media/review_1_v2.png" alt="guest_review" className='guest-review shadow'/>
                        <img src="/Media/review_2_v2.png" alt="guest_review" className='guest-review shadow'/>
                        <img src="/Media/review_3_v2.png" alt="guest_review" className='guest-review shadow'/>
                        <img src="/Media/review_4_v2.png" alt="guest_review" className='guest-review shadow'/>
                        <img src="/Media/review_5_v2.png" alt="guest_review" className='guest-review shadow'/>
                    </div>
                </div>
            </div>
            <div className="free-size dark section mobile-height-unset">
                <div className="description-container dark">
                    <span className='mb-5'>We have an In-house app</span>
                    <div className='big image-container app-preview'>
                        <img src="/Media/app_5.png" alt="app-preview" className='app-preview'/>
                        <img src="/Media/app_4.png" alt="app-preview" className='app-preview'/>
                        <img src="/Media/app_3.png" alt="app-preview" className='app-preview'/>
                    </div>
                </div>
            </div>
            <div className="free-size section mobile-height-unset">
                <div className="description-container dark">
                    <span className='mb-5'>We list on</span>
                    <div className="image-container platform mt-5 mb-5">
                        <img src="/Media/Icons/airbnb-circle-300x300.png" alt="platform_icon" className='platform-icon'/>
                        <img src="/Media/Icons/agoda-icon.svg" alt="platform_icon" className='platform-icon'/>
                        <img src="/Media/Icons/Booking_com_logo.png" alt="platform_icon" className='platform-icon'/>
                    </div>
                    <span className='mt-5'>for maximum exposure</span>
                </div>
            </div>
            <div className="big dark section">
                <div className="description-container dark">
                    <span className='mb-5'>Start your journey with us today</span>
                    <a href="/contact-us" className='btn btn-lg text-uppercase'>
                        Contact Us Now
                        <i className="fa fa-chevron-right ml-3"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Home;